<template lang="pug">
include ../../../configs/template
div.w-75.text-left.d-flex.flex-wrap.pt-6
  .row.ml-4
    div(v-if="checkAccess('main-editFullNameInfo')").col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +field-validation('sailorInfo.last_name_ukr', 'lastNameUK', '["required","alphaUA", "maxLength"]')
    div(v-if="checkAccess('main-editFullNameInfo')").col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +field-validation('sailorInfo.first_name_ukr', 'firstNameUK', '["required","alphaUA", "maxLength"]')
    div(v-if="checkAccess('main-editFullNameInfo')").col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +field-validation('sailorInfo.middle_name_ukr', 'middleNameUK', '["alphaUA", "maxLength"]')
    div(v-if="checkAccess('main-editFullNameInfoEng')").col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +field-validation('sailorInfo.last_name_eng', 'lastNameEN', '["required", "alphaEN", "maxLength"]')
    div(v-if="checkAccess('main-editFullNameInfoEng')").col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +field-validation('sailorInfo.first_name_eng', 'firstNameEN', '["required", "alphaEN", "maxLength"]')
    div.col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +date-picker-validation('sailorInfo.date_birth', 'dateBorn', 'birthDayObject', '["required", "minValue", "maxValue"]')(:max="new Date().toISOString()" min="1900-01-01")
    div(v-if="sailorInfo && sailorInfo.passport").col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +field('sailorInfo.passport.serial', '"serialAndNum"')(readonly)
    div.col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +field('sailorInfo.tax_number', '"taxNumber"')
    div.col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-3
      +select-validation('sailorInfo.sex', 'listSexes','sex', 'labelValue','["required"]')(return-object)
    div.col-xs-12.col-sm-12.col-md-6.col-lg-7.col-xl-6.d-flex.flex-column(v-if="checkAccess('add_sailorcontact')")
      div.w-100.d-flex.align-center
        PhoneNumberField(
          v-model="sailorInfo.phone_number"
          :error-messages="$valid.validation($v.sailorInfo.phone_number, ['minLength', 'maxLength'])").mb-6.px-0
        v-btn(@click="setContact('phone_number', sailorInfo.phone_number)" icon)
          v-icon(color="success") mdi-plus
      div(v-if="phoneList && phoneList.length").w-100
        label {{ $t('phoneNumber') }}
        div(v-for="(phone, index) in phoneList" :key="index").d-flex.align-center
          span {{ phone.value }}
          v-btn(v-if="checkAccess('delete_sailorcontact')" @click="deleteContact(phone)" color="error" icon)
            v-icon mdi-trash-can-outline
    div.col-xs-12.col-sm-12.mt-4(v-if="checkAccess('add_sailorcontact')")
      div.d-flex.align-center
        +field-validation('sailorInfo.email', 'email', '["email"]').mr-4
        v-btn(v-if="emailList && !emailList.length" @click="setContact('email', sailorInfo.email)" icon)
          v-icon(color="success") mdi-plus
      div.row
        EmailList(
          v-if="emailList?.filter(item => item.is_actual).length"
          :emailList="emailList?.filter(item => item.is_actual)"
          @deleteContact="deleteContact"
          :checkAccess="checkAccess('delete_sailorcontact')"
          label="is_actual_email"
          color="success").col-sm-12.col-md-3
        EmailList(
          v-if="emailList?.filter(item => item.is_verified).length"
          :emailList="emailList?.filter(item => item.is_verified)"
          @deleteContact="deleteContact"
          :checkAccess="checkAccess('delete_sailorcontact')"
          label="is_verified_email"
          color="success").col-sm-12.col-md-3
        EmailList(
          v-if="emailList?.filter(item => item.is_verified_isc).length"
          :emailList="emailList?.filter(item => item.is_verified_isc)"
          @deleteContact="deleteContact"
          :checkAccess="checkAccess('delete_sailorcontact')"
          label="is_verified_isc_email"
          color="success").col-sm-12.col-md-3
        EmailList(
          v-if="emailList?.filter(item => !item.is_actual && !item.is_verified && !item.is_verified_isc).length"
          :emailList="emailList?.filter(item => !item.is_actual && !item.is_verified && !item.is_verified_isc)"
          @deleteContact="deleteContact"
          :checkAccess="checkAccess('delete_sailorcontact')"
          label="is_not_actual_email"
          color="warning").col-sm-12.col-md-3
    div(v-if="checkAccess('main-editFullNameInfo') && sailorInfo.names_visibility").col-12.d-flex.mb-5
      +checkbox('sailorInfo.names_visibility.is_last_name_ukr_hidden', 'hideLastName').seafarerInfo-checkboxes(hide-details color="info").mr-8
      +checkbox('sailorInfo.names_visibility.is_first_name_ukr_hidden', 'hideFirstName').seafarerInfo-checkboxes(hide-details color="info").mr-8
      +checkbox('sailorInfo.names_visibility.is_middle_name_ukr_hidden', 'hideMiddleName').seafarerInfo-checkboxes(hide-details color="info")

</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapState } from 'vuex'
import { EditMainInfoSailor } from '@/mixins/validationRules'
import { contacts, SUCCESS_CODE, FOR_CALIDATION_OTHER_COUNTRY_PHONE } from '@/configs/constants'
import { clearBody } from '@/mixins/main'
export default {
  name: 'SailorMainInfoEditing',
  props: {
    sailorCurrentInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    PhoneNumberField: () => import('@/components/atoms/PhoneNumberField'),
    EmailList: () => import('./EmailList')
  },
  data () {
    return {
      checkAccess,
      newDataSailor: null,
      sailorInfo: {}
    }
  },
  computed: {
    ...mapState({
      id: state => state.sailor.sailorId,
      lang: state => state.main.lang,
      labelValue: state => (state.main.lang === 'en') ? 'value_eng' : 'value_ukr',
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      mappingCountry: state => state.directory.country,
      // mapping documents
      listSexes: state => state.directory.sex,
      errorsDetails: state => state.sailor.errorsDetails
    }),
    birthDayObject () {
      return this.sailorInfo.date_birth ? new Date(this.sailorInfo.date_birth) : null
    },
    phoneList () {
      return this.sailorInfo.contact_info?.filter(el => el.type_contact !== contacts.email.id)
    },
    emailList: {
      get () {
        return this.sailorInfo.contact_info?.filter(el => el.type_contact === contacts.email.id)
      },
      set (newVal) {
        return newVal
      }
    }
  },
  validations () { return EditMainInfoSailor(this._data) },
  mounted () {
    this.sailorInfo = { ...this.sailorCurrentInfo }
  },
  methods: {
    ...mapActions(['updateSailorMainInfo', 'setSailorContact', 'deleteSailorContact']),

    setContact (typeContact, contact) {
      if (this.$v.sailorInfo.phoneNumber.$invalid) return this.$v.sailorInfo.phoneNumber.$touch()
      else if (this.$v.sailorInfo.email.$invalid) return this.$v.sailorInfo.email.$touch()
      this.$swal({
        icon: 'info',
        title: `${this.$t('Do you really want save contact')} ?`,
        buttons: [this.$t('no'), this.$t('yes')]
      }).then(async (response) => {
        let body = {
          type_contact: contacts[typeContact].id,
          value: contact,
          country: FOR_CALIDATION_OTHER_COUNTRY_PHONE.find(el => contact.includes(el.dial_code))?.abbr || null
        }
        if (response) {
          const saveContact = await this.setSailorContact({ body })
          if (SUCCESS_CODE.includes(saveContact.code)) {
            this.sailorInfo[typeContact] = null
            this.sailorInfo.contact_info = [...this.sailorInfo.contact_info, saveContact.data]
            this.$notification.success('setSailorContact')
          }
        }
      })
    },
    deleteContact (contact) {
      if (this.$v.sailorInfo.phoneNumber.$invalid) return this.$v.sailorInfo.phoneNumber.$touch()
      else if (this.$v.sailorInfo.email.$invalid) return this.$v.sailorInfo.email.$touch()
      this.$swal({
        icon: 'warning',
        title: `${this.$t('Do you really want delete contact')} ?`,
        buttons: [this.$t('no'), this.$t('yes')]
      }).then(async (response) => {
        if (response) {
          const deleteContact = await this.deleteSailorContact({ contactId: contact.id })
          if (SUCCESS_CODE.includes(deleteContact.code)) {
            this.emailList = this.emailList.filter(val => {
              if (val.id === contact.id) {
                if (val.is_actual || val.is_verifed) {
                  val.is_actual = false
                  val.is_verified = false
                  this.$notification.success('notify.success.contactStatusWasChange')
                }
              }
              this.sailorInfo.contact_info = this.sailorInfo.contact_info.filter(el => el.id !== contact.id)
              return val
            })
            this.$notification.success('deleteSailorContact')
          }
        }
      })
    },

    /** Check field validation */
    validateForm () {
      if (this.$v.$invalid) this.$v.$touch()
      else this.saveInfo()
    },

    async saveInfo () {
      const body = {
        last_name_eng: this.sailorInfo.last_name_eng,
        first_name_eng: this.sailorInfo.first_name_eng,
        sex: this.sailorInfo.sex.id,
        date_birth: this.sailorInfo.date_birth,
        tax_number: this.sailorInfo.tax_number
      }
      if (this.sailorInfo.passport?.inn) delete body.tax_number
      if (checkAccess('main-editFullNameInfo')) {
        body.first_name_ukr = this.sailorInfo.first_name_ukr
        body.last_name_ukr = this.sailorInfo.last_name_ukr
        body.middle_name_ukr = this.sailorInfo.middle_name_ukr
        body.names_visibility = this.sailorInfo.names_visibility
      }
      this.$parent.isLoading = true
      const response = await this.updateSailorMainInfo({ id: this.id, body: { ...clearBody({ ...body }, ['middle_name_ukr']) } })
      if (response) {
        this.$parent.edit = false
        this.$parent.isLoading = false
      }
    }
  }
}
</script>
